import { LitElement, html, css } from 'lit-element'

//函數
import { codeGeneration } from './js/code-generation-api';
//

export default class CodeGenerationClass extends LitElement {
    constructor () {
        super()
        window.fetch('config.json').then(res => res.json()).then(config => {
          this.defaultIndex = config.defaultIndex
          this.defaultBackend = config.defaultBackend
          this.urlColumn = config.urlColumn || 'url'
          this.init()
        })
      }
    
      init () {
        this.yourText='';
        this.resultText='';
          
      }
      static get properties () {
        return {
          // service: { type: Object }
          yourText : {type: String},
          resultText : {type: String}
        }
      }
      async generateResult(){
        var yourLoginStateEle=document.getElementById('yourLoginState');
        var yourSubscribeStateEle=document.getElementById('yourSubscribeState');
        if(yourLoginStateEle.value=='0'){
            alert('please log in first')
            return;
        }else{
            if(yourSubscribeStateEle.value=='0'){
                alert('Please go to the home page to subscribe')
                return;
            }else{
              this.resultText=await codeGeneration(this.yourText)
              const resultTextElement = this.shadowRoot.getElementById('resultText')
              resultTextElement.value=this.resultText;
            }
        }
          
      }

      
      static get styles () {
          return css`
          body {
              margin: 2em 5em;
              font-family:Georgia, "Times New Roman", Times, serif;
          }
          h1, legend {
              font-family:Arial, Helvetica, sans-serif;
          }
          label, input, select {
              display:block;
          }
          input, select {
              margin-bottom: 1em;
          }
          fieldset {
              margin-bottom: 2em;
              padding: 1em;
          }
          fieldset fieldset {
              margin-top: 1em;
              margin-bottom: 1em;
          }
          input[type="checkbox"] {
              display:inline;
          }
          .range {
              margin-bottom:1em;
          }	
          .text-type input, .text-type label {
              display:inline-block;
          }
          .select{
            float: left;
            
          }
          
          
          textarea#yourText {
              width: 100%;
          }
          textarea#resultText {
              width: 100%;
          }
          #register{
            margin: 2em 5em;
              font-family:Georgia, "Times New Roman", Times, serif;
          }

          #all {
            margin-left:4%;
            margin-right:2%;
            margin-top:15%;
      
          }
          
          
          
            .generateResult{
                width: 40px;
                height: 40px:
                cursor:pointer;

            }
          `
        }
  
      render () {
          return html`

          <div id="all">
            
              <form id="register">
              <h3>code-generation tool</h3>
              <fieldset>
                  <fieldset> 
                  <legend>input your prompt, code-generation:</legend> 
                  <div> 
                      <label>
                          <textarea id="yourText" name="yourText" rows="4" required="please input your content" .value=${this.yourText} @input=${e => { this.yourText = e.target.value }}></textarea>
                      </label>
                  </div>
                  </fieldset>
                  
                  <fieldset> 
                  <legend>result:</legend> 
                  <div> 
                      <label>
                          <textarea id="resultText" name="resultText" rows="10" value="${this.resultText}"" ></textarea>
                      </label>
                  </div>
                  
                  </fieldset>
                  <div> 
                  <img src="assets/icons8-enter-key-100-2.png" class="generateResult" @click=${() => { this.generateResult() }} />
                  </div> 
              </fieldset>
              
          
              </form> 
          
              <grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration>
          </div>
          
    
  
          `
      }
  }
  
  
  