const NLPCloudClient = require('nlpcloud');

export async function textToHeadline(yourText){
  const client = new NLPCloudClient('fast-gpt-j','795acbfa429ac7ab6d716010f1772607c7d4ea96')
  // Returns an Axios promise with the results.
  // In case of success, results are contained in `response.data`. 
  // In case of failure, you can retrieve the status code in `err.response.status` 
  // and the error message in `err.response.data.detail`.
  const result = await client.summarization(yourText).then(function (response) {
      // console.log(response.data);
      return response.data.summary_text;
    })
    .catch(function (err) {
      console.error(err.response.status);
      console.error(err.response.data.detail);
    });
    console.log('result:')
    console.log(result)
    return result;
  }
