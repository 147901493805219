const NLPCloudClient = require('nlpcloud');

const nLPCloudClient = new NLPCloudClient('fast-gpt-j','795acbfa429ac7ab6d716010f1772607c7d4ea96')
// Returns an Axios promise with the results.
// In case of success, results are contained in `response.data`. 
// In case of failure, you can retrieve the status code in `err.response.status` 
// and the error message in `err.response.data.detail`.

// export async function textToKeyword(yourText){
//   var url1 = "https://api.nlpcloud.io/v1/gpu/fast-gpt-j/kw-kp-extraction"
//   var headers = {
//     "Authorization": "Token 795acbfa429ac7ab6d716010f1772607c7d4ea96",
//     "Content-Type": "application/json"
// }
//   var requestData = {"text":yourText};
//   var result = (fetch(url1, {
//       method : "POST",
//       mode: 'cors',
//       headers: headers,
//       body: JSON.stringify(requestData)
//     })
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error(response.error)
//       }
//       return response.json();
//     })
//     .then(data => {
      
//       return data;
//     })
//     .catch(function(error) {
//       console.error('textToSummary():請求錯誤1,error:'+error)
//     }))
//   return result;
// }

export async function textToKeyword(yourText){
  const NLPCloudClient = require('nlpcloud');

  const client = new NLPCloudClient('fast-gpt-j','795acbfa429ac7ab6d716010f1772607c7d4ea96', true)
  // Returns an Axios promise with the results.
  // In case of success, results are contained in `response.data`. 
  // In case of failure, you can retrieve the status code in `err.response.status` 
  // and the error message in `err.response.data.detail`.
  const result = await client.kwKpExtraction(yourText).then(function (response) {
      // console.log(response.data);
      return response.data.keywords_and_keyphrases;
    })
    .catch(function (err) {
      console.error(err.response.status);
      console.error(err.response.data.detail);
    });
    console.log('result:')
    console.log(result)
    return result;
}



export async function quickstart() {
    // // Imports the Google Cloud client library
    // const language = require('@google-cloud/language');
  
    // // Instantiates a client
    // const client = new language.LanguageServiceClient();
  
    // // The text to analyze
    // const text = 'Hello, world!';
  
    // const document = {
    //   content: text,
    //   type: 'PLAIN_TEXT',
    // };
  
    // // Detects the sentiment of the text
    // const [result] = await client.analyzeSentiment({document: document});
    // const sentiment = result.documentSentiment;
  
    // console.log(`Text: ${text}`);
    // console.log(`Sentiment score: ${sentiment.score}`);
    // console.log(`Sentiment magnitude: ${sentiment.magnitude}`);
  }