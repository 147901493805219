
const NLPCloudClient = require('nlpcloud');
export async function speechToTextApi(yourAudio){
  const client = new NLPCloudClient('whisper','795acbfa429ac7ab6d716010f1772607c7d4ea96',true)
  // Returns an Axios promise with the results.
  // In case of success, results are contained in `response.data`. 
  // In case of failure, you can retrieve the status code in `err.response.status` 
  // and the error message in `err.response.data.detail`.
  const result = await client.asr(yourAudio).then(function (response) {
      // console.log(response.data);
      return response.data.text;
    })
    .catch(function (err) {
      console.error(err.response.status);
      console.error(err.response.data.detail);
    });
    console.log('result:')
    console.log(result)
    return result;
}



export async function speechToTextApi2(){
    var result = {};
    var url1 = "https://speech.googleapis.com/v1p1beta1/speech:recognize";
    // var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
    var headers = {
        'Content-Type': 'application/json'
    }
    var requestData = {
      "audio": {
        "content": "/* Your audio */"
      },
      "config": {
        "enableAutomaticPunctuation": true,
        "encoding": "LINEAR16",
        "languageCode": "en-US",
        "model": "default"
      }
    };
    result = (await fetch(url1, {
        method : "POST",
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error)
          }
          return response.json();
        })
        .then(data => {
          
          return data;
        })
        .catch(function(error) {
          console.error('textToSpeechApi():請求錯誤1,error:'+error.message)
        }))
    return result;
}