import { LitElement, html, css } from 'lit-element'
//函數
import { initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import { getFirebaseConfig } from './firebase-config.js';
import { initFirebaseAuth,loadMessages,signOutUser,signInByGoogle} from './firebase-function.js'
//

export default class FirebaseLoginUiClass extends LitElement {
    constructor () {
        super()
        window.fetch('config.json').then(res => res.json()).then(config => {
          this.defaultIndex = config.defaultIndex
          this.defaultBackend = config.defaultBackend
          this.urlColumn = config.urlColumn || 'url'
          this.init()
        })
    }
  
    init () {
      

        //初始化賬號登錄
        const firebaseAppConfig = getFirebaseConfig();
        // TODO 0: Initialize Firebase
        initializeApp(firebaseAppConfig);
        // TODO 12: Initialize Firebase Performance Monitoring
        initFirebaseAuth();
        loadMessages();
        // TODO: Enable Firebase Performance Monitoring.
        getPerformance();
        //  

    }

    static get properties () {
        return {
          
        }
    }
    static get styles () {
        return css`
        
        `
      }

    render () {
        return html`

        `
    }
}

