import { LitElement, html, css } from 'lit-element'
import FirebaseLoginUiClass from './firebase-login-ui'
import ClipFront from './clip-front'
import TextToSpeechUi from './text-to-speech-ui'
import Gpt2TextGenerateClass from './gpt2-text-generate-ui';
import TextToSummaryClass from './text-to-summarization-ui';
import TextToKeywordClass from './text-to-keyword-ui';
import GrammarSpellingCorrectionUiClass from './grammar-spelling-correction-ui';
import TextToHeadlineClass from './text-to-headline-ui';
import LanguageDectionClass from './language-detection-ui';
import ParaphrasingClass from './paraphrasing-ui';
import CodeGenerationClass from './code-generation-ui';
import SpeechToTextClass from './speech-to-text-ui';
import Gpt3SummaryClass from './gpt3-summarization-ui';
import Gpt3ComplateClass from './gpt3-complate-ui';
import Gpt3RewriteClass from './gpt3-rewrite-ui';
//函數

import { gpt2Query } from './js/gpt2-api.js';

//

class MainClass extends LitElement {
    constructor () {
      super()
      window.fetch('config.json').then(res => res.json()).then(config => {
        this.defaultIndex = config.defaultIndex
        this.defaultBackend = config.defaultBackend
        this.urlColumn = config.urlColumn || 'url'
        this.init()
      })
    }
  
    init () {
      
        
        
        
    }

    static get properties () {
        return {
          service: { type: Object }
          
        }
    }

    static get styles () {
      return css`
      
      `
    }
    
  render () {
      return html``
  }
  
}

//初始化
// customElements.define('main-ui', MainClass);
customElements.define('firebase-login-ui', FirebaseLoginUiClass);
// customElements.define('clip-front', ClipFront);
// customElements.define('text-to-speech-ui', TextToSpeechUi);
customElements.define('text-to-summary-ui', TextToSummaryClass);
customElements.define('text-to-keyword-ui', TextToKeywordClass);
customElements.define('grammar-spelling-correction-ui', GrammarSpellingCorrectionUiClass);
customElements.define('text-to-headline-ui', TextToHeadlineClass);
customElements.define('language-detection-ui', LanguageDectionClass);
customElements.define('paraphrasing-ui', ParaphrasingClass);
customElements.define('code-generation-ui', CodeGenerationClass);
customElements.define('speech-to-text-ui', SpeechToTextClass);
customElements.define('gpt3-summarization-ui', Gpt3SummaryClass);
customElements.define('gpt3-complate-ui', Gpt3ComplateClass);
customElements.define('gpt3-rewrite-ui', Gpt3RewriteClass);



//初始化textToSpeechApi
// textToSpeechApi();
//初始化text-to-keyword
// quickstart()
//todo gpt2Query
// gpt2Query()

if(document.getElementById("index-page")) 
{ 
  
  var yourLoginStateEle=document.getElementById('yourLoginState');
  var yourSubscribeStateEle=document.getElementById('yourSubscribeState');
  var userSettingBtnEle=document.getElementById('userSettingBtn');
  var textToSummaryEle=document.getElementById('textToSummary')
  var textToKeywordEle=document.getElementById('textToKeyword')
  var grammarSpellingCorrectionEle=document.getElementById('grammarSpellingCorrection')
  var textToHeadlineEle=document.getElementById('textToHeadline')
  var languageDetectionEle=document.getElementById('languageDetection')
  var paraphrasingEle=document.getElementById('paraphrasing')
  var codeGenerationEle=document.getElementById('codeGeneration')
  var speechToTextEle=document.getElementById('speechToText')
  var gpt3SummaryEle=document.getElementById('gpt3Summary')
  var gpt3ComplateEle=document.getElementById('gpt3Complate')
  var gpt3RewriteEle=document.getElementById('gpt3Rewrite')

  //默認隱藏
  document.getElementById('text-to-keyword-ui').style='display: none;';
  document.getElementById('grammar-spelling-correction-ui').style='display: none;';
  document.getElementById('text-to-headline-ui').style='display: none;';
  document.getElementById('language-detection-ui').style='display: none;';
  document.getElementById('paraphrasing-ui').style='display: none;';
  document.getElementById('code-generation-ui').style='display: none;';
  document.getElementById('speech-to-text-ui').style='display: none;';
  document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  //
  userSettingBtnEle.addEventListener('click',()=>{
    if(yourLoginStateEle.value=='0'){
      alert('please log in first')
    }else{
      // document.getElementsByClassName('userSettingA').href='user-setting.html';
      window.location.replace('http://'+window.location.host+'/user-setting.html') 
    }
  });
  //
  textToSummaryEle.addEventListener('click',()=>{
    document.getElementById('text-to-summary-ui').style='display: block;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  textToKeywordEle.addEventListener('click',()=>{
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: block;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  grammarSpellingCorrectionEle.addEventListener('click',()=>{
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: block;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  textToHeadlineEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: block;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  languageDetectionEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: block;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  paraphrasingEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: block;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  codeGenerationEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: block;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  speechToTextEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: block;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  gpt3SummaryEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: block;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  gpt3ComplateEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: block;';
  document.getElementById('gpt3-rewrite-ui').style='display: none;';

  });
  gpt3RewriteEle.addEventListener('click',()=>{
    
    document.getElementById('text-to-summary-ui').style='display: none;';
    document.getElementById('text-to-keyword-ui').style='display: none;';
    document.getElementById('grammar-spelling-correction-ui').style='display: none;';
    document.getElementById('text-to-headline-ui').style='display: none;';
    document.getElementById('language-detection-ui').style='display: none;';
    document.getElementById('paraphrasing-ui').style='display: none;';
    document.getElementById('code-generation-ui').style='display: none;';
    document.getElementById('speech-to-text-ui').style='display: none;';
    document.getElementById('gpt3-summarization-ui').style='display: none;';
  document.getElementById('gpt3-complate-ui').style='display: none;';
  document.getElementById('gpt3-rewrite-ui').style='display: block;';

  });
}




// var allElement1=customElements.get('text-to-summary-ui')
// document.querySelector('text-to-summary-ui').style="display:none";
// var allElement2=TextToKeywordClass.shadowRoot.getElementById('all-2');
// allElement1.style='display:none';
// allElement2.style='';

