import { LitElement, html, css } from 'lit-element'

import { gpt2Query } from './js/gpt2-api.js';
//

export default class Gpt2TextGenerateClass extends LitElement {
    constructor () {
      super()
      window.fetch('config.json').then(res => res.json()).then(config => {
        this.defaultIndex = config.defaultIndex
        this.defaultBackend = config.defaultBackend
        this.urlColumn = config.urlColumn || 'url'
        this.init()
      })
    }
  
    init () {
        this.yourText='';
        this.resultText='';
        
        
    }
    static get properties () {
      return {
        // service: { type: Object }
        yourText : {type: String},
        resultText : {type: String}
      }
    }
    async generateResult(){
        this.resultText=gpt2Query(this.yourText)
    }

    static get styles () {
        return css`
        body {
            margin: 2em 5em;
            font-family:Georgia, "Times New Roman", Times, serif;
        }
        h1, legend {
            font-family:Arial, Helvetica, sans-serif;
        }
        label, input, select {
            display:block;
        }
        input, select {
            margin-bottom: 1em;
        }
        fieldset {
            margin-bottom: 2em;
            padding: 1em;
        }
        fieldset fieldset {
            margin-top: 1em;
            margin-bottom: 1em;
        }
        input[type="checkbox"] {
            display:inline;
        }
        .range {
            margin-bottom:1em;
        }	
        .text-type input, .text-type label {
            display:inline-block;
        }
        .select{
          float: left;
          
        }
        
        
        textarea#yourText {
            width: 100%;
        }
        textarea#resultText {
            width: 100%;
        }
        #register{
          margin: 2em 5em;
            font-family:Georgia, "Times New Roman", Times, serif;
        }
        #aiType {
            width: 22px;
            margin-left:0%;
            vertical-align:middle;
            cursor:pointer;
          }
        `
      }

    render () {
        return html`
        <body data-new-gr-c-s-check-loaded="14.1086.0" data-gr-ext-installed="">
            <form id="register">
            <h3>gpt2-text-generate tool</h3>
            <fieldset>
                <fieldset> 
                <legend>gpt2-text-generate:</legend> 
                <div> 
                    <label>
                        <textarea id="yourText" name="yourText" rows="3" required=""></textarea>
                    </label>
                </div>
                </fieldset>
                
                <fieldset> 
                <legend>result:</legend> 
                <div> 
                    <label>
                        <textarea id="resultText" name="resultText" rows="12" value="${this.resultText}"" ></textarea>
                    </label>
                </div>
                
                </fieldset>
                <div> 
                <img src="assets/tool-box.png" id="aiType" @click=${() => { this.generateResult() }} />
                </div> 
            </fieldset>
            
        
            </form> 
        
            <grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration>
        </body>
      

        `
    }
}





