/* globals customElements, FileReader */
import { LitElement, html, css } from 'lit-element'

//

export default class TextToSpeechUi extends LitElement {
  constructor () {
    super()
    window.fetch('config.json').then(res => res.json()).then(config => {
      this.defaultIndex = config.defaultIndex
      this.defaultBackend = config.defaultBackend
      this.urlColumn = config.urlColumn || 'url'
      this.init()
    })
  }

  init () {

  }
 

  static get properties () {
    return {
      service: { type: Object }
      
    }
  }

  static get styles () {
    return css`
    body {
        margin: 2em 5em;
        font-family:Georgia, "Times New Roman", Times, serif;
    }
    h1, legend {
        font-family:Arial, Helvetica, sans-serif;
    }
    label, input, select {
        display:block;
    }
    input, select {
        margin-bottom: 1em;
    }
    fieldset {
        margin-bottom: 2em;
        padding: 1em;
    }
    fieldset fieldset {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    input[type="checkbox"] {
        display:inline;
    }
    .range {
        margin-bottom:1em;
    }	
    .text-type input, .text-type label {
        display:inline-block;
    }
    .select{
      float: left;
      
    }
    #all {
      margin-left:4%;
      margin-right:2%;
      margin-top:13%;

    }

    textarea#yourText {
        width: 100%;
    }
    #register{
      margin: 2em 5em;
      font-family:Georgia, "Times New Roman", Times, serif;
    }
    `
  }

  render () {
    return html`
    <form id="register">
  <h3>Text to speak tool</h3>
    <fieldset> 
      <legend>Text to speak:</legend> 
      <div> 
          <label>
            <textarea id="yourText" name="yourText" rows="9" required="" width="60%"></textarea>
          </label>
      </div>
      <fieldset> 
        <legend>text type</legend> 
        <div class="text-type"> 
          <input id="textType1" name="textType1" type="radio"> 
          <label for="textType1">text</label> 
          <input id="textType2" name="textType2" type="radio">
          <label for="textType2">ssml</label> 
        </div> 
      </fieldset>
      <fieldset> 
        <legend>params</legend> 
        <label for="languageCode">Language / locale</label>
        <select id="languageCode">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="vw">VW</option>
          <option value="audi" selected="">Audi</option>
        </select>
        <label for="voiceType">Voice type</label>
        <select id="voiceType">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="vw">VW</option>
          <option value="audi" selected="">Audi</option>
        </select>
        <label for="voiceName">Voice name</label>
        <select id="voiceName">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="vw">VW</option>
          <option value="audi" selected="">Audi</option>
        </select>
        <label for="audioEncoding">Audio device profile</label>
        <select id="audioEncoding">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="vw">VW</option>
          <option value="audi" selected="">Audi</option>
        </select>
      
        <div>
          <label>Speed: <output name="output" onforminput="value=a.value">2.00</output>
          <input id="speakingRate" name="speakingRate" type="range" min="0.25" max="4.00" value="2.00">
          
          </label>
          <label>Pitch:<output name="output" onforminput="value=a.value">0</output>
            <input id="pitch" name="pitch" type="range" min="-20" max="20" value="0">
            
            </label>
        </div>
      </fieldset>
      <div> 
        <button type="submit">SPEAK IT</button> 
      </div> 
    </fieldset>
    
    
    <!-- <fieldset> 
      
    </fieldset>  -->
  </form> 

  <grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration>
    `
  }
}


//
// var aiTypeElement= document.getElementById('aiType');
// var productsElement = document.getElementById('products');
// // //
// async function aiTypeFunc () {
    
//   //todo 彈出浮動列表，選擇ai-type
//   productsElement.innerHTML('')



// aiTypeElement.addEventListener('click', aiTypeFunc);

//  }