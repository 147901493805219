/* globals fetch */

import JsonBigint from 'json-bigint'

export default class ClipService {
  constructor (backend) {
    this.backend = backend
  }

  async getIndices () {
    const result = JsonBigint.parse(await (await fetch(this.backend + `/indices-list`, {
    })).text())

    return result
  }

  async callClipService (text, image, imageUrl, embeddingInput, modality, numImages, indexName, numResultIds, useMclip, hideDuplicateImages, useSafetyModel, useViolenceDetector, aestheticScore, aestheticWeight, scrollCount) {
    console.log('calling', text, image, imageUrl, embeddingInput, modality, numImages, indexName, numResultIds, useMclip, hideDuplicateImages, useSafetyModel, useViolenceDetector, aestheticScore, aestheticWeight, scrollCount)
    var result = [];
    var url1 = "https://lexica.art/api/v1/search?q="+text;
    var url2 = "https://lexica.art/api/v1/search?q="+imageUrl;
    var headers = {}
    if(text!=null&&image==null&&imageUrl==null){
      result = (await fetch(url1, {
        method : "GET",
        mode: 'cors',
        headers: headers
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error)
          }
          return response.json();
        })
        .then(data => {
          var result=data.images;
          var result2=[];
          for(var i=0;i<result.length;i++){
            var obj=result[i];
            var obj2={}
            obj2.id=obj.id;
            obj2.url=obj.src;
            obj2.srcSmall=obj.srcSmall;
            obj2.caption=obj.prompt;
            obj2.gallery=obj.gallery;
            obj2.promptDetail='prompt: '+'width:'+obj.width+',height:'+obj.height+',seed:'+obj.seed+',guidance:'+obj.guidance+',nsfw:'+obj.nsfw;
            obj2.similarity=1;
            obj2.image=undefined;
            result2[i]=obj2;
          }
          return result2;
        })
        .catch(function(error) {
          console.error('callClipService():請求錯誤1,error:'+error.message)
        }))
    }else if(image!=null){
      result = JsonBigint.parse(await (await fetch(this.backend + `/knn-service`, {
        method: 'POST',
        body: JSON.stringify({
          text,
          image,
          'image_url': imageUrl,
          'embedding_input': embeddingInput,
          modality,
          'num_images': numImages,
          'indice_name': indexName,
          'num_result_ids': numResultIds,
          'use_mclip': useMclip,
          'deduplicate': hideDuplicateImages,
          'use_safety_model': useSafetyModel,
          'use_violence_detector': useViolenceDetector,
          'aesthetic_score': aestheticScore,
          'aesthetic_weight': aestheticWeight
        })
      })).text())
    }else{
      result = (await fetch(url2, {
        method : "GET",
        mode: 'cors',
        headers: headers
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.error)
          }
          return response.json();
        })
        .then(data => {
          var result=data.images;
          var result2=[];
          for(var i=0;i<result.length;i++){
            var obj=result[i];
            var obj2={}
            obj2.id=obj.id;
            obj2.url=obj.src;
            obj2.srcSmall=obj.srcSmall;
            obj2.caption=obj.prompt;
            obj2.gallery=obj.gallery;
            obj2.promptDetail='prompt: '+'width:'+obj.width+',height:'+obj.height+',seed:'+obj.seed+',guidance:'+obj.guidance+',nsfw:'+obj.nsfw;
            obj2.similarity=1;
            obj2.image=undefined;
            result2[i]=obj2;
            
          }
          return result2;
        })
        .catch(function(error) {
          console.error('callClipService():請求錯誤2,error:'+error.message)
        }))
    }


    return result;
  }

  async getMetadata (ids, indexName) {
    const result=JsonBigint.parse(await (await fetch(this.backend + `/metadata`, {
      method: 'POST',
      body: JSON.stringify({
        ids,
        'indice_name': indexName
      })
    })).text())
    
    console.log('meta data:')
    console.log(result);
    return result
  }
}
