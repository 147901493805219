const NLPCloudClient = require('nlpcloud');



export async function textToSummary2(yourText){
  var url1 = "https://api.nlpcloud.io/v1/t5-base-en-generate-headline/summarization"
  var headers = {
    "Authorization": `Token 795acbfa429ac7ab6d716010f1772607c7d4ea96`
}
  // var requestData = {"text":yourText};
  var requestData = {'text':'One month after the United States began what has become a troubled rollout of a national COVID '};
  // var result = await fetch(url1, {
  //     method : "POST",
  //     // mode: 'no-cors',
  //     headers: headers,
  //     body: requestData
  //   })
  //   .then((response) => {
  //     if (!response.ok) {
  //       throw new Error(response.error)
  //     }
  //     return response.json();
  //   })
  //   .then(data => {
      
  //     return data;
  //   })
  //   .catch(function(error) {
  //     console.error('textToSummary():請求錯誤1,error:'+error)
  //   });
    console.log(requestData)
    const test = { 'Authorization': 'Token 795acbfa429ac7ab6d716010f1772607c7d4ea96','Content-Type': 'application/json'}
    console.log(test)
    const response = await fetch(
      'https://api.nlpcloud.io/v1/t5-base-en-generate-headline/summarization',
      {
          headers: { 'Authorization': 'Token 795acbfa429ac7ab6d716010f1772607c7d4ea96','Content-Type': 'application/json'},
          method: 'POST',
          body: requestData
      }
  );
  const result = await response.json();
  console.log(result)
  return result;
}



export async function textToSummary(yourText){
  const client = new NLPCloudClient('bart-large-samsum','795acbfa429ac7ab6d716010f1772607c7d4ea96')
  // Returns an Axios promise with the results.
  // In case of success, results are contained in `response.data`. 
  // In case of failure, you can retrieve the status code in `err.response.status` 
  // and the error message in `err.response.data.detail`.
  const result = await client.summarization(yourText).then(function (response) {
      // console.log(response.data);
      return response.data.summary_text;
    })
    .catch(function (err) {
      console.error(err.response.status);
      console.error(err.response.data.detail);
    });
    console.log('result:')
    console.log(result)
    return result;
  }
